import service from '@/utils/request'

//首页资讯
export function articles_home(){
    return service({
        url:'api/article/rec',
        method:'get',
    })
}
//资讯列表
export function articles(params){
    return service({
        url:'api/article/list',
        method:'get',
        params
    })
}
//资讯详情
export function articles_info(id){
    return service({
        url:'api/article/'+id,
        method:'get',
    })
}
//资讯上下篇
export function article_next(id){
    return service({
        url:'api/article/next/'+id,
        method:'get',
    })
}
//分类详情
export function articles_cates(id){
    return service({
        url:'web/v1/article-cates/info/'+id,
        method:'get',
    })
}
