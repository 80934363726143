<template>
    <div class="leave_message">
        <div class="content">
            <div class="line_01">我要留言</div>
            <div class="messagestitle">{{messagesShow?'进行留言':'留言须知'}}</div>
            <div v-if="messagesShow" style="padding:0px 38px;">
                <el-form label-width="95px" ref="vform" :rules="rules" :model="feedbacks" label-position="right">
                    <el-form-item label="分类：" prop="cate_id">
                        <el-radio-group v-model="feedbacks.cate_id">
                            <el-radio :label="1">咨询</el-radio>
                            <el-radio :label="2">建议</el-radio>
                            <el-radio :label="3">其他</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="留言标题：" prop="title">
                        <el-input placeholder="请输入留言标题" v-model="feedbacks.title"></el-input>
                    </el-form-item>
                    <el-form-item label="留言内容：" prop="content">
                        <el-input placeholder="请输入留言内容" type="textarea" resize="none" :rows="8" v-model="feedbacks.content"></el-input>
                    </el-form-item>
                    <el-form-item label="验证码：" prop="code">
                        <div class="fx">
                            <el-input placeholder="请输入验证码" v-model="feedbacks.code"></el-input>
                            <div style="margin-left:20px;cursor: pointer;height:40px;" @click="identifyCode='',makeCode(identifyCodes, 4)">
                            <Identify :identifyCode="identifyCode"></Identify>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="是否公开：" prop="user_open">
                        <el-radio-group v-model="feedbacks.user_open">
                            <el-radio :label="true">公开</el-radio>
                            <el-radio :label="false">不公开</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
                <div class="footer">
                    <div class="btn" @click="messagesShow = false">取消</div>
                    <div class="btns" @click="messagesBtn">留言</div>
                </div>
            </div>
            <div v-else>
                <div v-html="rule.content"></div>
                <div class="btns" @click="JumpMessages">留言</div>
            </div>
        </div>
    </div>
</template>

<script>
import {articles_info} from '@/api/news.js'
import {feedback} from '@/api/feedback.js'
export default {
    data() {
        let codes = (rule, value, callback) => { //验证器
          if (!this.feedbacks.code) {    
            callback(new Error('请输入验证码'));
          }else if (this.identifyCode!=this.feedbacks.code) {    
            callback(new Error('请输入正确的验证码'));
          } else {
            callback();
          }
        };
        return {
            rule:{},
            messagesShow:false,
            feedbacks:{
                user_open:false
            },
            identifyCodes: "1234567890",
            identifyCode: "",
            rules:{
              cate_id:{ required: true,message: '请选择分类', trigger: 'blur' },
              title:{ required: true,message: '请输入留言标题', trigger: 'blur' },
              content:{ required: true,message: '请输入留言内容', trigger: 'blur' },
              user_open:{ required: true,message: '请选择是否公开', trigger: 'blur' },
              code:{ required: true,validator:codes, trigger: 'blur' },
            }
        };
    },
    created() {
        this.getrules()
        this.identifyCode=''
        this.makeCode(this.identifyCodes, 4)
    },
    mounted() {

    },
    methods: {
        JumpMessages(){
            if(localStorage.getItem('sheixetoken')){
                this.identifyCode=''
                this.makeCode(this.identifyCodes, 4)
                this.messagesShow = true
            }else{
                this.$router.push('/login')
            }
        },
        //留言须知
        getrules(){
            articles_info(this.$route.query.id).then(res=>{
                if(res.code==200){
                    this.rule = res.data
                }
            })
        },
        //我要留言
        messagesBtn(){
          this.$refs['vform'].validate(valid=>{
            if(valid){
                feedback(this.feedbacks).then(res=>{
                    if(res.code==200){
                        this.$message.success(res.msg);
                        this.feedbacks = {}
                        this.messagesShow = false
                    }
                })
            }
          })
        },
        //验证码
        randomNum(min, max) {
          return Math.floor(Math.random() * (max - min) + min);
        },
        refreshCode() {
          this.identifyCode = "";
          this.makeCode(this.identifyCodes, 4);
        },
        makeCode(o, l) {
          for (let i = 0; i < l; i++) {
            this.identifyCode += this.identifyCodes[
              this.randomNum(0, this.identifyCodes.length)
            ];
          }
          console.log(this.identifyCode);
        }
    }
};
</script>

<style scoped lang="scss">
    .leave_message{
        margin: 20px auto;
        .content{
            margin-top: 40px;
            .line_01{ 
                width:100px;
                padding: 0 20px 0;
                margin:0px auto 30px;  
                line-height: 2px;  
                color: #CD0000;
                font-weight: bold;
                border-left: 80px solid #CD0000;  
                border-right: 80px solid #CD0000;  
                text-align: center;  
            }
            .messagestitle{
                background: #EEEEEE;
                padding: 10px 24px;
                margin-bottom: 20px;
            }
            .footer{
                width:400px;
                margin: auto;
                display: flex;
                justify-content: space-around;
            }
            .btns,.btn{
                width: 120px;
                line-height: 36px;
                text-align:center;
                border-radius:4px;
                background: #CD0000;
                color: #fff;
                border:1px solid #CD0000;
                cursor: pointer;
                margin: auto;
            }
            .btn{
                color: #CD0000;
                background: #fff;
            }
        }
    }
</style>
